/* styles.css */
.prevStepButton {
  border-radius: 20px;
  border-color: #444;
}

.nextStepButton {
  border-radius: 20px;
  right: 0px;
}

.completeRegButton {
}

.buttonContainer {
  position: fixed;
  left: 320px;
  top: 0px;
  top: -20px;
  height: 76px;
  padding: 16px 20px;
  width: calc(100% - 320px);
  z-index: 100;
}

.stepperDiv {
  min-width: 400px;
}

@media (max-width: 1200px) {
  .stepperDiv {
    display: none;
  }
  .completeRegButton {
    width: 136px !important;
  }
}

@media (max-width: 648px) {
  .buttonContainer {
    display: none;
  }
}
