.member-row {
  cursor: pointer;
}

.member-row:hover {
  font-weight: bold;
}

.selected-member {
  font-weight: bold;
  color: #238be6;
}

.family-member-row {
  cursor: pointer;
}

.family-member-row:hover {
  font-weight: bold;
}

.family-member-row td {
  padding-left: 20px;
}

/* Text color for Approved and Denied members */
.member-approved {
  color: green;
}

.member-denied {
  color: red;
}

/* Ensure hover and selected-member styles take precedence */
.member-row:hover,
.family-member-row:hover,
.selected-member {
  font-weight: bold;
}

.member-row:hover,
.family-member-row:hover {
  cursor: pointer;
}
