.navbar {
  transition: 'width 0.3s ease';
  /*not working, not sure if routing*/
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-6)
  );
  height: rem(750px);
  width: rem(800px);
  display: flex;
  flex-direction: column;
  /*  border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));*/
  height: 100vh;
}

.wrapper {
  display: flex;
  flex: 1;
}

.aside,
.asideClosed {
  width: 100%;
  flex: 0 0 rem(60px);
  background-color: var(--mantine-color-body);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
}

/* .asideClosed {
  width: unset;
} */

.main {
  margin-left: 0px;
  flex: 1;
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  );
}

.mainLinkClosed,
.mainLink {
  width: 48px;
  height: 48px;
  height: rem(44px);
  width: rem(48px);
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: left;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));

  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-5)
    );
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);
    }
  }
}

.mainLink svg,
.mainLinkClosed svg {
  margin-left: 10px;
  width: 26px !important;
}

.mainLinkClosed p {
  display: none;
}

.mainLink {
  width: 100%;
  width: rem(210px);
}

.asideClosed.mainLink {
  margin-right: 200px;
}

.title {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  /*  margin-bottom: var(--mantine-spacing-xl);*/
  background-color: var(--mantine-color-body);
  padding: var(--mantine-spacing-md);
  padding-top: rem(18px);
  height: rem(60px);
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  height: rem(60px);
  padding-top: var(--mantine-spacing-md);
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
  margin-bottom: var(--mantine-spacing-xl);
}

.linkDisabled,
.link {
  display: block;
  text-decoration: none;
  /*  border-top-right-radius: var(--mantine-radius-md);*/
  /*  border-bottom-right-radius: var(--mantine-radius-md);*/
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  padding: 0 var(--mantine-spacing-md);
  font-size: var(--mantine-font-size-sm);
  /* margin-right: var(--mantine-spacing-md); */
  font-weight: 500;
  height: rem(44px);
  line-height: rem(44px);
  padding: 8px 20px;
  width: 100%;

  &[data-active] {
    &,
    &:hover {
      border-left-color: var(--mantine-color-blue-filled);
      background-color: var(--mantine-color-blue-filled);
      color: var(--mantine-color-white);
    }
  }
}

.linkDisabled {
  pointer-events: none;
  cursor: default;
  color: inherit;
  text-decoration: none;
}

.link {
  width: 280px;
  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-1),
      var(--mantine-color-dark-5)
    );
    color: light-dark(var(--mantine-color-dark), var(--mantine-color-light));
  }
  cursor: pointer;
}

.linkDisabled {
  pointer-events: none;
  cursor: default;
  color: inherit;
  text-decoration: none;
}

.link {
  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-1),
      var(--mantine-color-dark-5)
    );
    color: light-dark(var(--mantine-color-dark), var(--mantine-color-light));
  }
  cursor: pointer;
}

.userInfo {
  position: 'absolute';
  bottom: '0px';
  border: 1px solid #ccc;
  background-color: rgba(120, 120, 120, 0.1);
  border-radius: 10px;
  padding: 10px 10px;
  width: 100%;
  overflow: hidden;
}

.username {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  display: block;
  text-wrap: nowrap;
}
