@media (prefers-color-scheme: dark) {
  :root {
    --sticky-bg-light: var(--mantine-color-body);
    /* Dark mode background */
  }
}

.root {
  overflow-x: auto;
  max-width: 100%;
}

/* bug alternate panels showing */
.root > div:nth-child(2),
.root > div:nth-child(3) {
  display: none;
}

.root table {
  border-collapse: collapse;
  width: 100%;
  /* Set table width */
}

.root thead {
  border-bottom: 1px solid #888 !important;
}

.root thead th {
  text-align: center !important;
  border: none !important;
}

.root th,
.root td {
  padding: 8px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  min-width: 180px;
}

.root th > div > div > div {
  width: 100%;
  text-align: center;
}

/* .root tr {
  cursor: grabbing;
} */

.root th:first-child,
.root td:first-child {
  position: sticky;
  left: 0;
  background: var(--sticky-bg-light);
  z-index: 1;
  /* Ensure it appears above scrolling content */
}

/* Style for selected cells */
.selected {
  background-color: rgba(33, 150, 243, 0.1) !important;
  color: var(--mantine-color-blue-light-color);
  border: none;
}

.mantine-datatable-empty-state {
  display: none;
}

.benefit {
  cursor: pointer;
}

.benefit:hover {
  color: var(--mantine-color-blue-light-color);
  /* background-color: #ebe9fa !important; */
}

.ppopover {
  color: black;
  background-color: #ebe9fa !important;
}

.hiddenBenefits table {
  border-collapse: collapse;
  width: 100%;
  /* Set table width */
}

/* hiddenBenefits */

.hiddenBenefits table {
  margin-top: 10px;
}

.hiddenBenefits header {
  background: '#ebe9fa';
  color: '#333';
  font-weight: 'bold';
  text-align: 'center';
}

.hiddenBenefits th {
  background: var(--mantine-color-blue-light);
  /* color: var(--mantine-color-blue-light-color); */
}

.hiddenBenefits th,
.hiddenBenefits td {
  padding: 8px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  min-width: 180px;
  text-align: center;
}

.hiddenBenefits th > div > div > div {
  width: 100%;
  text-align: center;
}

.hiddenBenefits th:first-child > div > div > div,
.hiddenBenefits td:first-child {
  text-align: left;
}

.hiddenBenefits > div:nth-child(2),
.hiddenBenefits > div:nth-child(3) {
  display: none;
}
