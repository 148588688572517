body {
  background-color: #f5f5f5 !important;
  /* background: linear-gradient(to bottom, #036AC7, #02B1EE, #B2EFFF); */
  /* background: linear-gradient(to bottom, #4f4f4f, #b0b0b0, #e0e0e0); */
  background-attachment: fixed;
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mantine-Button-outline {
  border-color: #007bff !important; /* Outline button border color */
  color: #007bff !important; /* Outline button text color */
}

.mantine-Button-outline:hover {
  background-color: rgba(
    0,
    123,
    255,
    0.1
  ) !important; /* Hover effect for outline */
}

.mantine-Container-root {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.mantine-AppShell-root {
  background-color: #f5f5f5; /* Light gray background */
}

@media (max-width: 600px) {
  .mantine-AppShell-navbar {
    width: 80% !important;
  }
}

.mantine-Tabs-tab {
  background-color: #ffffff;
  border: none;
  border-bottom: 2px solid #e0e0e0; /* Light gray border for not selected tabs */
}

.mantine-Tabs-tab[aria-selected='true'] {
  font-weight: 600;
  color: #2100e9;
  border-bottom: none; /* Remove bottom border for selected tab */
}

.mantine-Tabs-panel {
  background-color: #ffffff;
  padding: 20px; /* Extra padding */
  border-radius: 0px; /* Rounded corners */
}

.ryze-list td,
th {
  padding: 10px;
}

.ryze-list th {
  padding: 10px;
  background-color: #e6e6e6; /* Light gray for odd rows */
}

.ryze-list.table-striped tr:nth-child(odd) {
  background-color: #f9f9f9; /* Light gray for odd rows */
}

.ryze-list.table-highlight tr:hover {
  cursor: pointer;
  background-color: #eaeaea; /* Light hover color */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-mantine-color-scheme='dark'] {
  body {
    background-color: #121212 !important; /* Dark mode background */
    background: none;
  }
  .mantine-AppShell-root {
    background-color: #333333; /* Light gray background */
  }
  .mantine-Container-root {
    background-color: #333333;
  }
  .mantine-Tabs-tab {
    background-color: #333333;
  }
  .mantine-Tabs-tab[aria-selected='true'] {
    background-color: #4c4c4c;
    color: #fff;
  }
  .mantine-Tabs-panel {
    background-color: #4c4c4c;
  }
  .ryze-list th {
    background-color: #282c34; /* Light gray for odd rows */
  }
  .ryze-list.table-striped tr:nth-child(odd) {
    background-color: #4c4c4c; /* Light gray for odd rows */
  }
  .ryze-list.table-highlight tr:hover {
    background-color: #666666; /* Light hover color */
  }
}
